@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Aldo";
  src: local("Aldo"), url(./assets/fonts/Aldo.ttf) format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: "futura_light";
  src: local("futura_medium_bt"), url(assets/fonts/futura_medium_bt.ttf) format("truetype");
}

@font-face {
  font-family: "futura_md_bt_bold";
  src: local("futura_md_bt_bold"),
    url(./assets/fonts/futura_md_bt_bold.ttf) format("truetype");
}

@font-face {
  font-family: "DINPro_Black";
  src: local("DINPro_Black"),
    url(./assets/fonts/DINPro_Black.otf) format("opentype");
}
